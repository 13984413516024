<template>
    <tn-ranges title="TN Ranges" @on-change="onChange"/>
    <br/>
    Result: {{ result }}
</template>
<script lang="ts">
import { Options, Vue}  from 'vue-class-component';
import TnRanges from '@/components/TnRanges.vue';
import TnRange from '@/models/tnRange';

@Options({
  components: { TnRanges },
  data(){
      return {
          result: ""
      }
  }, 
  methods: {
      onChange(val: TnRange[]){
          this.result = JSON.stringify(val, null, 2);
      }
  }

})
export default class TestTnRanges extends Vue {}
</script>