import { render } from "./TestTnRanges.vue?vue&type=template&id=ec872648"
import script from "./TestTnRanges.vue?vue&type=script&lang=ts"
export * from "./TestTnRanges.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ec872648"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ec872648', script)) {
    api.reload('ec872648', script)
  }
  
  module.hot.accept("./TestTnRanges.vue?vue&type=template&id=ec872648", () => {
    api.rerender('ec872648', render)
  })

}

script.__file = "src/views/TestTnRanges.vue"

export default script